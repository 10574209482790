<template>
    <div class="terminal">
        <b-form inline class="row py-1" @submit.prevent="saveConfig">
            <div class="col-sm-auto">
                <label for="bet-amount-alarm">{{
                    $t('bet amount alarm')
                }}</label>
                <VueNumberFormat
                    id="bet-amount-alarm"
                    class="form-control bg-black text-light text-end border border-secondary"
                    v-model:value="edit.bet_amount_alarm"
                    :options="{
                        precision: 0,
                        prefix: '',
                        suffix: '',
                        decimal: '.',
                        thousand: ',',
                        acceptNegative: false,
                        isInteger: true,
                    }"></VueNumberFormat>
            </div>
            <div class="col-sm-auto">
                <label for="win-amount-alarm">{{
                    $t('win amount alarm')
                }}</label>
                <VueNumberFormat
                    id="win-amount-alarm"
                    class="form-control bg-black text-light text-end border border-secondary"
                    v-model:value="edit.win_amount_alarm"
                    :options="{
                        precision: 0,
                        prefix: '',
                        suffix: '',
                        decimal: '.',
                        thousand: ',',
                        acceptNegative: false,
                        isInteger: true,
                    }"></VueNumberFormat>
            </div>
            <div class="col-sm-auto">
                <label for="lose-amount-alarm">{{
                    $t('lose amount alarm')
                }}</label>
                <VueNumberFormat
                    id="lose-amount-alarm"
                    class="form-control bg-black text-light text-end border border-secondary"
                    v-model:value="edit.lose_amount_alarm"
                    :options="{
                        precision: 0,
                        prefix: '',
                        suffix: '',
                        decimal: '.',
                        thousand: ',',
                        acceptNegative: false,
                        isInteger: true,
                    }"></VueNumberFormat>
            </div>
            <div class="col-sm-auto">
                <label>&nbsp;</label>
                <div class="button-items">
                    <button type="submit" class="btn btn-primary">
                        {{ $t('save') }}
                    </button>
                </div>
            </div>
            <div class="col">
                <label>&nbsp;</label>
                <div class="button-items">
                    <button
                        type="button"
                        class="btn btn-dark btn-rounded float-end font-size-18"
                        @click="logs = []">
                        <i class="bx bxs-eraser"></i>
                    </button>
                    <button
                        type="button"
                        class="btn btn-dark btn-rounded float-end font-size-18"
                        @click="muted = !muted">
                        <i
                            :class="`bx bxs-volume-${
                                muted ? 'mute text-secondary' : 'full'
                            }`"></i>
                    </button>
                </div>
            </div>
        </b-form>
        <ul>
            <li class="fw-bold">
                <span class="text-start">{{ $t('datetime') }}</span>
                <span>{{ $t('title') }}</span>
                <span>{{ $t('vendor') }}</span>
                <span>{{ $t('game') }}</span>
                <span>{{ $t('username') }}</span>
                <span>{{ $t('status') }}</span>
                <span class="text-end">{{ $t('amount') }}</span>
                <span class="text-end">{{ $t('balance') }}</span>
            </li>
            <li v-for="(r, i) in logs" :key="i" :id="`log-${i}`">
                <span class="text-start">{{ dateYmD(r.datetime, true) }}</span>
                <span>{{ r.title }}</span>
                <span>{{ r.vendor }}</span>
                <span>{{ r.game }}</span>
                <span>{{ r.username }}</span>
                <span
                    :class="{
                        'text-success': r.status == 'bet',
                        'text-danger': r.status == 'win',
                        'text-info': r.status == 'lose',
                    }"
                    >{{ $t(r.status) }}</span
                >
                <span class="text-end" :class="{ 'text-warning': r.warn }">{{
                    numberString(r.amount)
                }}</span>
                <span class="text-end">{{ numberString(r.balance) }}</span>
            </li>
        </ul>
        <audio
            class="hidden-audio"
            ref="betLimitPlayer"
            controls
            :muted="muted">
            <source
                :src="require('@/assets/audio/success.mp3')"
                type="audio/mp3" />
        </audio>
        <audio
            class="hidden-audio"
            ref="winLimitPlayer"
            controls
            :muted="muted">
            <source
                :src="require('@/assets/audio/dropmoney.mp3')"
                type="audio/mp3" />
        </audio>
        <audio
            class="hidden-audio"
            ref="loseLimitPlayer"
            controls
            :muted="muted">
            <source
                :src="require('@/assets/audio/cashing.mp3')"
                type="audio/mp3" />
        </audio>
    </div>
</template>

<script>
import Datepicker from 'vue3-datepicker';
import Layout from '../../../../layouts/main';
import PageHeader from '@/components/page-header';
import LoadingTable from '@/components/loading-table';
import Pagination from '../../../../../components/pagination.vue';
import Multiselect from '@vueform/multiselect';
import Switches from 'vue-switches';
import Swal from 'sweetalert2';
import { mapActions, mapGetters } from 'vuex';
import convert from '../../../../../mixins/convert';
import datetime from '../../../../../mixins/datetime';
import number from '../../../../../mixins/number';
import ui from '../../../../../mixins/ui';
export default {
    components: {
        Layout,
        PageHeader,
        Pagination,
        Switches,
        LoadingTable,
        Multiselect,
        Datepicker,
    },
    mixins: [datetime, number, convert, ui],
    data() {
        return {
            test: null,
            title: `agents`,
            // status_options: [
            //     { name: this.$t('pending'), value: 'pending' },
            //     { name: this.$t('review'), value: 'review' },
            //     { name: this.$t('completed'), value: 'complete' },
            //     { name: this.$t('cancelled'), value: 'cancel' },
            // ],
            logs: [],
            edit: {
                bet_amount_alarm: this.$route.query['bet_amount_alarm'] || 0,
                win_amount_alarm: this.$route.query['win_amount_alarm'] || 0,
                lose_amount_alarm: this.$route.query['lose_amount_alarm'] || 0,
            },
            config: {
                bet_amount_alarm: this.$route.query['bet_amount_alarm'] || 0,
                win_amount_alarm: this.$route.query['win_amount_alarm'] || 0,
                lose_amount_alarm: this.$route.query['lose_amount_alarm'] || 0,
            },
            interacted: false,
            muted: true,
        };
    },
    methods: {
        saveConfig() {
            this.config.bet_amount_alarm = this.edit.bet_amount_alarm;
            this.config.win_amount_alarm = this.edit.win_amount_alarm;
            this.config.lose_amount_alarm = this.edit.lose_amount_alarm;
            this.$router.replace({ query: this.config });
            Swal.fire({
                background: 'transparent',
                color: '#fff',
                icon: 'success',
                title: this.$t('saved'),
                showConfirmButton: false,
                timer: 1000,
            });
        },
        appendLog(log) {
            var audioPlayer = null;
            log.datetime = new Date();
            if (
                log.status === 'bet' &&
                log.amount >= this.config.bet_amount_alarm
            ) {
                log.warn = true;
                audioPlayer = this.$refs.betLimitPlayer;
            } else if (
                log.status === 'win' &&
                log.amount >= this.config.win_amount_alarm
            ) {
                log.warn = true;
                audioPlayer = this.$refs.winLimitPlayer;
            } else if (
                log.status === 'lose' &&
                log.amount >= this.config.lose_amount_alarm
            ) {
                log.warn = true;
                audioPlayer = this.$refs.loseLimitPlayer;
            }

            if (this.interacted && audioPlayer) {
                audioPlayer.play();
            }
            this.logs.push(log);
            setTimeout(() => {
                document
                    .getElementById(`log-${this.logs.length - 1}`)
                    .scrollIntoView({ behavior: 'instant' });
            }, 100);
        },
    },
    mounted() {
        document.addEventListener('click', () => {
            this.interacted = true;
        });

        if (Boolean(window.PusherChannel)) {
            window.PusherChannel.bind('honorlink', (e) => {
                this.appendLog(e.message);
            });
        }
    },
    beforeUnmount() {
        if (Boolean(window.PusherChannel)) {
            window.PusherChannel.unbind('honorlink');
        }
    },
};
</script>

<style>
body {
    background: #000 !important;
}
</style>
